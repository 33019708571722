import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp, AlertCircle, Upload, Check } from 'lucide-react';
import { FILE_TYPE_FIELDS } from '../../config/mappingConfig';
import { FIELD_PATTERNS } from '../../config/headerMappingConfig';

const FILE_TYPE_PATTERNS = [
  {
    type: 'Facility Information',
    patterns: ['facility', 'fac', 'plant', 'site', 'location']
  },
  {
    type: 'Transport Information',
    patterns: ['transport', 'pipeline', 'pipe', 'shipping', 'trans']
  },
  {
    type: 'Well Information',
    patterns: ['well', 'injection', 'bore', 'drill']
  },
  {
    type: 'CO2 Monitoring',
    patterns: ['monitor', 'co2', 'carbon', 'measurement', 'sensor']
  }
];

const guessFileType = (fileName) => {
  const lowercaseName = fileName.toLowerCase();
  
  for (const { type, patterns } of FILE_TYPE_PATTERNS) {
    if (patterns.some(pattern => lowercaseName.includes(pattern))) {
      return type;
    }
  }
  
  return null;
};

const StyledSelect = ({ id, name, value, onChange, options, placeholder, className = "" }) => (
  <div className="relative">
    <select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={`
        appearance-none
        w-full
        px-4 py-2.5
        bg-white
        border border-gray-300
        rounded-lg
        shadow-sm
        text-gray-700
        focus:outline-none
        focus:ring-2
        focus:ring-blue-500
        focus:border-blue-500
        ${className}
      `}
    >
      <option value="">{placeholder}</option>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
  </div>
);

const FieldMapping = ({ files = [], onConfirm, error }) => {
  const [fileTypes, setFileTypes] = useState({});
  const [mappings, setMappings] = useState({});
  const [expandedFile, setExpandedFile] = useState(null);
  const [headers, setHeaders] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const uploadIndicatorRef = useRef(null);

  const readHeaders = async (file) => {
    try {
      const text = await file.text();
      const firstLine = text.split('\n')[0];
      const csvHeaders = firstLine.split(',').map(header => header.trim());
      setHeaders(prev => ({ ...prev, [file.name]: csvHeaders }));
      return csvHeaders;
    } catch (err) {
      console.error('Error reading CSV headers:', err);
      return [];
    }
  };

  const autoDetectFieldMappings = (fileName, fileType, csvHeaders) => {
    const normalizedHeaders = csvHeaders.map(header => 
      header.toLowerCase().replace(/[^a-z0-9]/g, '_')
    );

    const newMappings = { ...(mappings[fileName] || {}) };
    const patterns = FIELD_PATTERNS[fileType];
    const usedHeaders = new Set();

    Object.entries(patterns).forEach(([field, fieldPatterns]) => {
      if (newMappings[field]) return;

      let bestMatch = null;
      fieldPatterns.some(pattern => {
        const matchingIndex = normalizedHeaders.findIndex(header => 
          header.includes(pattern) && !usedHeaders.has(header)
        );
        
        if (matchingIndex !== -1) {
          bestMatch = csvHeaders[matchingIndex];
          usedHeaders.add(normalizedHeaders[matchingIndex]);
          return true;
        }
        return false;
      });

      if (bestMatch) {
        newMappings[field] = bestMatch;
      }
    });

    if (Object.keys(newMappings).length > 0) {
      setMappings(prev => ({
        ...prev,
        [fileName]: newMappings
      }));
    }
  };

  useEffect(() => {
    if (files.length === 0) {
      onConfirm({});
      return;
    }

    const detectedTypes = {};
    files.forEach(file => {
      const guessedType = guessFileType(file.name);
      if (guessedType) {
        detectedTypes[file.name] = guessedType;
      }
    });
    
    setFileTypes(prev => ({
      ...prev,
      ...detectedTypes
    }));

    const processFiles = async () => {
      for (const [fileName, fileType] of Object.entries(detectedTypes)) {
        const file = files.find(f => f.name === fileName);
        if (file) {
          const headers = await readHeaders(file);
          if (headers.length > 0) {
            autoDetectFieldMappings(fileName, fileType, headers);
          }
        }
      }
    };

    processFiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, onConfirm]);

  const handleExpandFile = (fileName) => {
    setExpandedFile(expandedFile === fileName ? null : fileName);
  };

  const handleFileTypeSelect = async (fileName, type) => {
    setFileTypes(prev => ({ ...prev, [fileName]: type }));
    const file = files.find(f => f.name === fileName);
    if (file) {
      const headers = await readHeaders(file);
      if (headers.length > 0) {
        autoDetectFieldMappings(fileName, type, headers);
      }
    }
  };

  const getAvailableHeaders = (fileName, currentField) => {
    const allHeaders = headers[fileName] || [];
    const currentMappings = mappings[fileName] || {};
    const usedHeaders = Object.values(currentMappings);
    
    return allHeaders.filter(header => 
      !usedHeaders.includes(header) || currentMappings[currentField] === header
    );
  };

  const handleFieldMapping = (fileName, requiredField, csvHeader) => {
    setMappings(prev => ({
      ...prev,
      [fileName]: {
        ...prev[fileName],
        [requiredField]: csvHeader
      }
    }));
  };

  const isFullyMapped = (fileName) => {
    if (!fileTypes[fileName]) return false;
    
    const requiredFields = Object.entries(FILE_TYPE_FIELDS[fileTypes[fileName]])
      .filter(([_, info]) => info.required)
      .map(([field]) => field);
    
    const currentMappings = mappings[fileName] || {};
    return requiredFields.every(field => currentMappings[field]);
  };

  const canConfirm = () => {
    return files.every(file => {
      if (!fileTypes[file.name]) return false;
      
      const requiredFields = Object.entries(FILE_TYPE_FIELDS[fileTypes[file.name]])
        .filter(([_, info]) => info.required)
        .map(([field]) => field);
      
      const currentMappings = mappings[file.name] || {};
      return requiredFields.every(field => currentMappings[field]);
    });
  };

  const handleConfirm = async () => {
    setIsUploading(true);
    
    const initialProgress = {};
    files.forEach(file => {
      initialProgress[file.name] = 0;
    });
    setFileProgress(initialProgress);

    // Scroll to upload indicator after a short delay to ensure it's rendered
    setTimeout(() => {
      uploadIndicatorRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    const uploadPromises = files.map(async (file) => {
      for (let progress = 0; progress <= 100; progress += 5) {
        await new Promise(resolve => setTimeout(resolve, 50 + Math.random() * 100));
        setFileProgress(prev => ({
          ...prev,
          [file.name]: progress
        }));
      }
    });
  
    await Promise.all(uploadPromises);
    await new Promise(resolve => setTimeout(resolve, 500));
    onConfirm(mappings);
  };

  const uploadingIndicator = isUploading && (
    <div ref={uploadIndicatorRef} className="space-y-3 p-4 bg-blue-50 rounded-lg mb-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-3">
          <Upload className="w-5 h-5 text-blue-500 animate-bounce" />
          <span className="text-blue-700 font-medium">Uploading files...</span>
        </div>
      </div>
      <div className="space-y-3">
        {files.map(file => (
          <div key={file.name} className="space-y-1">
            <div className="flex justify-between text-sm">
              <span className="text-gray-600">{file.name}</span>
              <span className="text-blue-600">{fileProgress[file.name] || 0}%</span>
            </div>
            <div className="h-2 bg-blue-100 rounded-full">
              <div
                className="h-full bg-blue-500 rounded-full transition-all duration-150"
                style={{ width: `${fileProgress[file.name] || 0}%` }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-md flex items-start space-x-2">
          <AlertCircle className="w-5 h-5 text-red-500 mt-0.5" />
          <span className="text-red-700">{error}</span>
        </div>
      )}

      <div className="space-y-4">
        {files.map(file => (
          <div key={file.name} className="border rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow duration-200">
            <div className="p-4">
              <div className="flex items-center justify-between">
                <div className="flex-1 space-y-3">
                  <div className="flex items-center justify-between">
                    <h3 className="font-medium text-gray-900">{file.name}</h3>
                  </div>
                  <StyledSelect
                    id={'fileType-' + file.name}
                    name="fileType"
                    value={fileTypes[file.name] || ''}
                    onChange={(e) => handleFileTypeSelect(file.name, e.target.value)}
                    options={Object.keys(FILE_TYPE_FIELDS)}
                    placeholder="Select file type..."
                  />
                </div>
                <button
                  onClick={() => handleExpandFile(file.name)}
                  className={`
                    ml-4 p-2 rounded-full transition-all duration-200
                    ${isFullyMapped(file.name) 
                      ? 'bg-green-500 hover:bg-green-600' 
                      : 'hover:bg-gray-100'}
                  `}
                >
                  {isFullyMapped(file.name) ? (
                    <Check className="w-5 h-5 text-white" />
                  ) : (
                    expandedFile === file.name ? (
                      <ChevronUp className="w-5 h-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-500" />
                    )
                  )}
                </button>
              </div>

              {expandedFile === file.name && fileTypes[file.name] && headers[file.name] && (
                <div className="mt-4 pt-4 border-t">
                  <div className="flex items-center justify-between mb-4">
                    <h4 className="font-medium text-gray-900">Map Fields</h4>
                  </div>
                  <div className="space-y-4">
                    {Object.entries(FILE_TYPE_FIELDS[fileTypes[file.name]]).map(([field, info]) => (
                      <div key={field} className="flex items-start space-x-4">
                        <div className="w-1/3 pt-2">
                          <label className="block text-sm font-medium text-gray-700" htmlFor={'field-' + field}>
                            {info.description}
                            {info.required && <span className="text-red-500 ml-1">*</span>}
                          </label>
                          <span className="text-xs text-gray-500 mt-0.5 block">({info.type})</span>
                        </div>
                        <div className="w-2/3">
                          <StyledSelect
                            id={'field-' + field}
                            name={'field-' + field}
                            value={mappings[file.name]?.[field] || ''}
                            onChange={(e) => handleFieldMapping(file.name, field, e.target.value)}
                            options={getAvailableHeaders(file.name, field)}
                            placeholder="Select header..."
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {uploadingIndicator}

      <button
        onClick={handleConfirm}
        className={`
          w-full px-4 py-3
          rounded-lg font-medium
          transition-colors duration-200
          focus:outline-none focus:ring-2 focus:ring-offset-2
          ${canConfirm() && !isUploading
            ? 'bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500'
            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }
        `}
        disabled={!canConfirm() || isUploading}
      >
        {isUploading ? 'Uploading...' : 'Confirm Mappings'}
      </button>
    </div>
  );
};

export default FieldMapping;