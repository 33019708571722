// Common patterns for field names
export const FIELD_PATTERNS = {
    'Facility Information': {
      facilityName: ['facility_name', 'facility', 'name', 'site_name', 'plant_name'],
      location: ['location', 'site_location', 'facility_location', 'address', 'site_address'],
      operatorInfo: ['operator', 'operator_info', 'company', 'operator_name', 'company_name'],
      capacity: ['capacity', 'processing_capacity', 'annual_capacity', 'max_capacity', 'yearly_capacity']
    },
    'Transport Information': {
      pipelineId: ['pipeline_id', 'pipe_id', 'transport_id', 'line_id', 'pipeline_number'],
      startPoint: ['start_point', 'origin', 'source', 'from_location', 'start_location'],
      endPoint: ['end_point', 'destination', 'sink', 'to_location', 'end_location'],
      flowRate: ['flow_rate', 'transport_rate', 'flow', 'daily_flow', 'rate']
    },
    'Well Information': {
      wellId: ['well_id', 'well_number', 'well_name', 'injection_well_id', 'wellbore_id'],
      depth: ['depth', 'well_depth', 'total_depth', 'measured_depth', 'true_depth'],
      formation: ['formation', 'geological_formation', 'reservoir', 'formation_name', 'geology'],
      injectionRate: ['injection_rate', 'inj_rate', 'daily_injection', 'injection_flow', 'co2_injection_rate']
    },
    'CO2 Monitoring': {
      timestamp: ['timestamp', 'date', 'time', 'measurement_time', 'reading_time'],
      pressure: ['pressure', 'reservoir_pressure', 'well_pressure', 'bottom_pressure', 'psi'],
      temperature: ['temperature', 'temp', 'reservoir_temp', 'well_temperature', 'celsius'],
      co2Concentration: ['co2_concentration', 'concentration', 'co2_level', 'co2_percent', 'carbon_dioxide']
    }
  };