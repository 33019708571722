// src/components/steps/ProcessingStatus.jsx
import React, { useEffect } from 'react';
import { Check, X } from 'lucide-react';
import { useProcessingStatus } from '../../hooks/useProcessingStatus';

const ProcessingStatus = ({ jobId, error: externalError, onRetry, onComplete }) => {
  const { status, error: processingError, isComplete, disconnect } = useProcessingStatus(jobId);

  useEffect(() => {
    if (isComplete) {
      onComplete();
    }
    
    // Cleanup by disconnecting when component unmounts
    return () => {
      disconnect();
    };
  }, [isComplete, onComplete, disconnect]);


  const StatusIndicator = ({ type, label }) => (
    <div className="flex items-center space-x-4">
      <div className="w-32 md:w-40">
        <span className="text-sm font-medium text-gray-700">{label}</span>
      </div>
      <div className="flex-1">
        {externalError || processingError ? (
          <div className="flex items-center">
            <X className="text-red-600 h-5 w-5" />
            <span className="ml-2 text-sm text-red-600">
              {processingError || 'Processing failed'}
            </span>
          </div>
        ) : status[type].progress === 100 ? (
          <div className="flex items-center">
            <Check className="text-green-600 h-5 w-5" />
            <span className="ml-2 text-sm text-green-600">Complete</span>
          </div>
        ) : (
          <div className="relative w-full">
            <div className="h-2 bg-gray-200 rounded-full w-full">
              <div 
                className="h-full bg-blue-600 rounded-full transition-all duration-100"
                style={{ width: `${status[type].progress}%` }}
              />
            </div>
            <span className="absolute right-0 top-2 text-xs text-gray-500">
              {Math.round(status[type].progress)}%
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <StatusIndicator 
          type="ipfs"
          label="Uploading to IPFS"
        />
        <StatusIndicator 
          type="blockchain"
          label="Storing in MassCertificate"
        />
      </div>
      
      {(externalError || processingError) && (
        <button 
          onClick={onRetry}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Retry Processing
        </button>
      )}
    </div>
  );
};

export default ProcessingStatus;