const Alert = ({ children, className }) => {
    return (
      <div className={`rounded-lg border p-4 ${className}`}>
        {children}
      </div>
    );
};
  
const AlertDescription = ({ children }) => {
    return <div className="mt-2 text-sm">{children}</div>;
};
  
export { Alert, AlertDescription };
