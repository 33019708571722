import { useState, useEffect, useRef } from 'react';
import { ProcessingSocket, getProcessingStatus } from '../api/services';

export const useProcessingStatus = (jobId) => {
  const [status, setStatus] = useState({
    ipfs: { progress: 0, status: 'pending' },
    blockchain: { progress: 0, status: 'pending' }
  });
  const [error, setError] = useState(null);
  
  // Use refs to track mounted state and store socket instance
  const isMounted = useRef(true);
  const socketRef = useRef(null);
  
  useEffect(() => {
    // Set up mounted ref
    isMounted.current = true;
    
    // Safe state updates that only occur if component is mounted
    const safeSetStatus = (newStatus) => {
      if (isMounted.current) {
        setStatus(newStatus);
      }
    };
    
    const safeSetError = (newError) => {
      if (isMounted.current) {
        console.error('Safe Set Error:', error.response.data);
        setError(newError);
      }
    };

    const initializeStatus = async () => {
      if (!jobId) return;

      try {
        // Get initial status
        const initialStatus = await getProcessingStatus(jobId);
        safeSetStatus(initialStatus);

        // Set up WebSocket connection for real-time updates
        socketRef.current = new ProcessingSocket(jobId, (update) => {
          safeSetStatus(prevStatus => ({
            ...prevStatus,
            [update.type]: {
              progress: update.progress,
              status: update.status
            }
          }));
        });

        // Set up error handler for socket
        socketRef.current.onError((socketError) => {
          safeSetError(socketError.message);
        });
      } catch (err) {
        safeSetError(err.message);
      }
    };

    // Initialize status tracking
    initializeStatus();

    // Cleanup function
    return () => {
      isMounted.current = false;
      
      // Clean up socket connection if it exists
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [jobId]); // Only re-run if jobId changes

  // Compute completion status
  const isComplete = Object.values(status).every(s => 
    s.progress === 100 && s.status === 'complete'
  );

  // Expose a manual disconnect method
  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = null;
    }
  };

  return { 
    status, 
    error, 
    isComplete,
    disconnect // Allow manual disconnection if needed
  };
};