import { useState } from 'react';
import { mintTokens, generate8933Form } from '../api/services';

export const useTokenMinting = () => {
  const [mintingStatus, setMintingStatus] = useState({
    carbon: { status: 'pending', hash: null },
    vcm: { status: 'pending', hash: null },
    q45: { status: 'pending', hash: null }
  });
  const [error, setError] = useState(null);

  const mint = async (type, amount, recipientAddress) => {
    setError(null);
    try {
      setMintingStatus(prev => ({
        ...prev,
        [type]: { status: 'minting', hash: null }
      }));

      const result = await mintTokens(type, amount, recipientAddress);
      
      setMintingStatus(prev => ({
        ...prev,
        [type]: { status: 'complete', hash: result.transactionHash }
      }));

      return result;
    } catch (err) {
      setError(err.message);
      setMintingStatus(prev => ({
        ...prev,
        [type]: { status: 'error', hash: null }
      }));
      throw err;
    }
  };

  const generateForm = async (tokenData) => {
    try {
      const pdfBlob = await generate8933Form(tokenData);
      
      // Create download link
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Form_8933.pdf';
      document.body.appendChild(a);
      a.onclick = () => {
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 1000);
      };
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error('Generate Form Error:', error.response.data);
      setError(err.message);
      throw err;
    }
  };

  return {
    mintingStatus,
    error,
    mint,
    generateForm
  };
};