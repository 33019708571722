import React, { useState, useRef } from 'react';
import { ChevronDown, X, FileText, Check, LineChart, FileSpreadsheet, Plug2 } from 'lucide-react';

const MRV_PROVIDERS = [
  'Aurion',
  'Cority',
  'Subpart.AI'
];

const SCADA_PROVIDERS = [
  'Prediktor',
  'Rockwell Automation',
  'Schneider Electric',
  'Siemens',
  'ABB'
];

const TabButton = ({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium rounded-t-lg ${
      active
        ? 'bg-white text-blue-600 border-t border-x border-gray-300'
        : 'text-gray-600 hover:text-gray-800 hover:bg-white/50'
    }`}
  >
    {children}
  </button>
);

const StyledSelect = ({ id, name, value, onChange, options, placeholder, className = "" }) => (
  <div className="relative">
    <select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      className={`
        appearance-none
        w-full
        px-4 py-2.5
        bg-white
        border border-gray-300
        rounded-lg
        shadow-sm
        text-gray-700
        focus:outline-none
        focus:ring-2
        focus:ring-blue-500
        focus:border-blue-500
        ${className}
      `}
    >
      <option value="">{placeholder}</option>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
    <ChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
  </div>
);

const ProviderSelector = ({ 
  title, 
  providers, 
  selectedProvider, 
  onProviderChange, 
  connectButtonText,
  onConnect,
  disabled = false 
}) => (
  <div className="p-6 bg-white border-x border-b border-gray-300 rounded-b-lg h-full flex flex-col">
    <div className="mb-auto">
      <label htmlFor="provider-select" className="block text-sm font-medium text-gray-700 mb-2">
        {title}
      </label>
      <StyledSelect
        id="provider-select"
        name="provider-select"
        value={selectedProvider}
        onChange={onProviderChange}
        options={providers}
        placeholder="Select a provider..."
      />
    </div>
    
    <button
      onClick={onConnect}
      disabled={disabled || !selectedProvider} // Disable if no provider selected
      className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors mt-6
        ${disabled || !selectedProvider
          ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
          : 'bg-blue-600 text-white hover:bg-blue-700'
        }`}
    >
      <Plug2 className="w-5 h-5 mr-2" />
      {connectButtonText}
    </button>
  </div>
);

const UploadSection = ({ title, description, onDrop, isDragging, inputRef, icon: Icon }) => (
  <div
    className={`p-6 text-center cursor-pointer h-full ${
      isDragging ? 'bg-blue-50' : 'bg-white'
    }`}
    onDragOver={(e) => e.preventDefault()}
    onDrop={onDrop}
    onClick={() => inputRef.current?.click()}
  >
    <Icon className="mx-auto h-10 w-10 text-gray-400 mb-3" />
    <h3 className="font-medium text-gray-900 mb-2">{title}</h3>
    <p className="text-sm text-gray-600 mb-2">{description}</p>
    <p className="text-xs text-gray-500">Maximum file size: 5MB per file</p>
  </div>
);

const FileUpload = ({ onFileSelect, error }) => {
  const [activeReportTab, setActiveReportTab] = useState('permits');
  const [activeMonitoringTab, setActiveMonitoringTab] = useState('scada');
  const [isDragging, setIsDragging] = useState({ report: false, monitoring: false });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedMrvProvider, setSelectedMrvProvider] = useState('');
  const reportInputRef = useRef(null);
  const monitoringInputRef = useRef(null);
  const fileListRef = useRef(null); // a reference to file list section

  const isValidFile = (file) => {
    const isCSV = file.name.endsWith('.csv');
    const isUnder5MB = file.size <= 5 * 1024 * 1024;
    return isCSV && isUnder5MB;
  };

  const scrollToFileList = () => {
    if (fileListRef.current) {
      fileListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const processFiles = (files) => {
    const fileArray = Array.from(files);
    const validFiles = fileArray.filter(isValidFile);
    
    if (validFiles.length === 0) {
      console.error('Please select valid CSV files under 5MB.');
      return;
    }

    setSelectedFiles(prev => [...prev, ...validFiles]);

    setTimeout(scrollToFileList, 100); // Small delay to ensure state is updated
  };

  const handleDrop = (e, section) => {
    e.preventDefault();
    setIsDragging({ ...isDragging, [section]: false });
    processFiles(e.dataTransfer.files);
  };

  const handleFileSelect = (e) => {
    processFiles(e.target.files);
  };

  const removeFile = (fileToRemove) => {
    setSelectedFiles(files => 
      files.filter(file => file !== fileToRemove)
    );
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const handleConfirm = () => {
    if (selectedFiles.length > 0) {
      onFileSelect(selectedFiles);
    }
  };

  return (
    <div className="space-y-6">
    {/* Two Column Layout */}
    <div className="grid md:grid-cols-2 grid-cols-1 gap-6 md:gap-6 gap-4 border rounded-lg bg-gray-50 p-6 md:p-6 p-4">
      {/* Report Information Section */}
      <div>
        <h2 className="font-medium text-gray-900 mb-3 md:mb-3 mb-2 text-lg md:text-base">Report Data</h2>
        <div className="bg-white rounded-lg overflow-hidden shadow-sm">
          <input
            type="file"
            accept=".csv"
            ref={reportInputRef}
            onChange={handleFileSelect}
            multiple
            className="hidden"
          />
          <div className="border-b border-gray-300">
            <div className="flex space-x-1 px-1 pt-1">
              <TabButton
                active={activeReportTab === 'permits'}
                onClick={() => setActiveReportTab('permits')}
              >
                Permits
              </TabButton>
              <TabButton
                active={activeReportTab === 'files'}
                onClick={() => setActiveReportTab('files')}
              >
                Files
              </TabButton>
            </div>
          </div>

          {activeReportTab === 'permits' ? (
          <ProviderSelector
            title="Select MRV Manager"
            providers={MRV_PROVIDERS}
            selectedProvider={selectedMrvProvider}
            onProviderChange={(e) => setSelectedMrvProvider(e.target.value)}
            connectButtonText="Connect to MRV"
            onConnect={() => onFileSelect([])}
            disabled={false}
          />
          ) : (
          <UploadSection
            title="Upload Report Information"
            description="Upload facility, transport, and well information CSV files"
            onDrop={(e) => handleDrop(e, 'report')}
            isDragging={isDragging.report}
            inputRef={reportInputRef}
            icon={FileSpreadsheet}
          />
          )}
        </div>
      </div>

      {/* CO2 Monitoring Section */}
      <div className="md:mt-0 mt-2">
        <h2 className="font-medium text-gray-900 mb-3 md:mb-3 mb-2 text-lg md:text-base">Monitoring Data</h2>
        <div className="bg-white rounded-lg overflow-hidden shadow-sm">
          <input
            type="file"
            accept=".csv"
            ref={monitoringInputRef}
            onChange={handleFileSelect}
            multiple
            className="hidden"
          />
          <div className="border-b border-gray-300">
            <div className="flex space-x-1 px-1 pt-1">
              <TabButton
                active={activeMonitoringTab === 'scada'}
                onClick={() => setActiveMonitoringTab('scada')}
              >
                SCADA
              </TabButton>
              <TabButton
                active={activeMonitoringTab === 'files'}
                onClick={() => setActiveMonitoringTab('files')}
              >
                Files
              </TabButton>
            </div>
          </div>
          
          {activeMonitoringTab === 'scada' ? (
          <ProviderSelector
            title="Select SCADA Provider"
            providers={SCADA_PROVIDERS}
            selectedProvider={selectedProvider}
            onProviderChange={(e) => setSelectedProvider(e.target.value)}
            connectButtonText="Connect to SCADA"
            onConnect={() => onFileSelect([])}
            disabled={false}  // Make sure this is false
          />
          ) : (
            <UploadSection
              title="Upload CO2 Monitoring"
              description="Upload CO2 monitoring and measurement data CSV files"
              onDrop={(e) => handleDrop(e, 'monitoring')}
              isDragging={isDragging.monitoring}
              inputRef={monitoringInputRef}
              icon={LineChart}
            />
          )}
        </div>
      </div>
      </div>

      {/* Shared File List */}
      {selectedFiles.length > 0 && (
        <div ref={fileListRef} className="space-y-3 md:space-y-4">
          <div className="space-y-2 md:space-y-2">
            {selectedFiles.map((file, index) => (
              <div
                key={`${file.name}-${index}`}
                className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200 animate-flash"
              >
                <div className="flex items-center space-x-3">
                  <FileText className="h-5 w-5 text-blue-500" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">{file.name}</p>
                    <p className="text-xs text-gray-500">{formatFileSize(file.size)}</p>
                  </div>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(file);
                  }}
                  className="p-1 hover:bg-gray-100 rounded-full"
                >
                  <X className="h-4 w-4 text-gray-500" />
                </button>
              </div>
            ))}
          </div>

          <button
            onClick={handleConfirm}
            className="w-full flex items-center justify-center px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
          >
            <Check className="w-5 h-5 mr-2" />
            Confirm Files and Continue
          </button>
        </div>
      )}
    </div>
  );
};

export default FileUpload;