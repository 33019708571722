import CarbonTokenApp from './components/CarbonTokenApp';

function App() {
  return (
    <div>
      <CarbonTokenApp />
    </div>
  );
}

export default App;