const Card = ({ children, className }) => {
    return (
      <div className={`rounded-lg border bg-white shadow ${className}`}>
        {children}
      </div>
    );
};

const CardHeader = ({ children }) => {
    return <div className="p-3">{children}</div>;
};
  
const CardTitle = ({ children }) => {
    return <h3 className="text-lg font-semibold">{children}</h3>;
};
  
const CardDescription = ({ children }) => {
    return <p className="text-sm text-gray-600">{children}</p>;
};
  
const CardContent = ({ children }) => {
    return <div className="p-2 pt-0 md:p-6">{children}</div>;
};
  
export { Card, CardHeader, CardTitle, CardDescription, CardContent };
