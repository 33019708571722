// src/components/CarbonTokenApp.jsx
import React, { useState } from 'react';
import { Upload, FileUp, AlertCircle, Check, X, RefreshCcw } from 'lucide-react';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Card, CardContent, CardHeader } from '@/components/ui/card';

// Import API services and hooks
import { uploadFiles, createProcessingJob } from '../api/services';

// Import components
import FileUpload from './steps/FileUpload';
import FieldMapping from './steps/FieldMapping';
import ProcessingStatus from './steps/ProcessingStatus';
import Results from './steps/Results';

const CarbonTokenApp = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedKeys, setUploadedKeys] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [processingStatus, setProcessingStatus] = useState({
    ipfs: 'pending',
    blockchain: 'pending',
    tokens: 'pending'
  });

  // Mock data for demonstration
  const mockResults = {
    ipfsLink: 'ipfs://QmYwAPJzv5CZsnA625s3Xf2nemtYgPpHdWEz79ojWnPbdG/',
    massCertificateData: {
      totalMass: '1500',
      location: 'North Dakota, USA',
      timestamp: '2024-03-15'
    },
    tokensMinted: 1500,
    recipientAddress: '0x277852BBC858F7F5B6CB647f3693B4ce8e2291',
    networkFee: 45035,
    transactionDate: new Date(),
    transactionHash: '0x123...',
    timeRemaining: 30,
    status: 'Pending',
    q45Data: {
      tokensMinted: 1500,
      recipientAddress: '0x277852BBC858F7F5B6CB647f3693B4ce8e2292',
      networkFee: 52142,
      transactionDate: new Date(),
      transactionHash: '0x456...',
      timeRemaining: 15,
      status: 'Pending'
    },
    vcmData: {
      tokensMinted: 1500,
      recipientAddress: '0x277852BBC858F7F5B6CB647f3693B4ce8e2292',
      networkFee: 52142,
      transactionDate: new Date(),
      timeRemaining: 15,
      transactionHash: '0x789...'
    }
  };

  const steps = [
    { number: 1, title: 'Provide Data', icon: FileUp },
    { number: 2, title: 'Map Fields', icon: Upload },
    { number: 3, title: 'Processing', icon: AlertCircle },
    { number: 4, title: 'Results', icon: Check }
  ];

  const handleFileUpload = async (files) => {
    const invalidFiles = files.filter(file => !file.name.endsWith('.csv'));
    
    if (invalidFiles.length > 0) {
      setError({
        step: 1,
        message: `Invalid file type${invalidFiles.length > 1 ? 's' : ''}. Please upload only CSV files.`,
        type: 'error'
      });
      return;
    }

    if (files.some(file => file.size > 5 * 1024 * 1024)) {
      setError({
        step: 1,
        message: 'One or more files exceed the 5MB size limit.',
        type: 'error'
      });
      return;
    }

    try {
      // Upload files to S3 and get their keys
      const keys = await uploadFiles(files, (fileName, progress) => {
        // You could add a progress indicator here if desired
        console.log(`Uploading ${fileName}: ${progress}%`);
      });
      
      setSelectedFiles(files);
      setUploadedKeys(keys);
      setError(null);
      setCurrentStep(2);
    } catch (err) {
      setError({
        step: 1,
        message: err.message || 'Error uploading files. Please try again.',
        type: 'error'
      });
    }
  };

  const handleFieldMapping = async (mappings) => {
    try {
      // Create processing job with uploaded files and mappings
      const job = await createProcessingJob(uploadedKeys, mappings);
      setJobId(job.id);
      setError(null);
      setCurrentStep(3);
    } catch (err) {
      setError({
        step: 2,
        message: err.message || 'Error processing files. Please try again.',
        type: 'error'
      });
    }
  };

  const handleProcessingAction = (action) => {
    if (action === 'error') {
      setError({
        step: 3,
        message: 'Processing timeout. Please try again.',
        type: 'error'
      });
    } else if (action === 'retry') {
      setError(null);
      setCurrentStep(4);
      setProcessingStatus({
        ipfs: 'complete',
        blockchain: 'complete',
        tokens: 'complete'
      });
    }
  };

  const ErrorAlert = ({ message, onRetry }) => (
    <Alert className="bg-red-50 border-red-200 mb-4">
      <X className="h-4 w-4 text-red-600" />
      <AlertDescription className="text-red-800 flex items-center justify-between">
        <span>{message}</span>
        {onRetry && (
          <button
            onClick={onRetry}
            className="flex items-center text-sm text-red-600 hover:text-red-700"
          >
            <RefreshCcw className="h-4 w-4 mr-1" />
            Retry
          </button>
        )}
      </AlertDescription>
    </Alert>
  );

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">Carbon Token Minting Portal</h1>
          <p className="text-gray-600">Upload your data to mint carbon tokens</p>
        </div>

        {/* Progress Steps */}
        <div className="flex justify-between mb-8">
          {steps.map((step) => {
            const StepIcon = step.icon;
            const isError = error?.step === step.number;
            return (
              <div 
                key={step.number}
                className={`flex flex-col items-center w-1/4 ${
                  isError ? 'text-red-600' :
                  currentStep === step.number ? 'text-blue-600' : 
                  currentStep > step.number ? 'text-green-600' : 'text-gray-400'
                }`}
              >
                <div className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 ${
                  isError ? 'bg-red-100 border-2 border-red-600' :
                  currentStep === step.number ? 'bg-blue-100 border-2 border-blue-600' :
                  currentStep > step.number ? 'bg-green-100 border-2 border-green-600' : 'bg-gray-100 border-2 border-gray-400'
                }`}>
                  {isError ? <X className="w-5 h-5" /> : <StepIcon className="w-5 h-5" />}
                </div>
                <span className="text-sm font-medium">{step.title}</span>
              </div>
            );
          })}
        </div>

        {/* Main Content Area */}
        <Card className="mb-8">
          <CardHeader>
          </CardHeader>
          <CardContent>
            {error && <ErrorAlert message={error.message} onRetry={currentStep === 3 ? () => handleProcessingAction('retry') : null} />}

            {currentStep === 1 && (
              <FileUpload onFileSelect={handleFileUpload} error={error?.step === 1} />
            )}

            {currentStep === 2 && (
              <FieldMapping 
                files={selectedFiles}
                onConfirm={handleFieldMapping} 
                error={error?.step === 2} 
              />
            )}

            {currentStep === 3 && (
              <ProcessingStatus 
                jobId={jobId}
                status={processingStatus}
                error={error?.step === 3}
                onRetry={handleProcessingAction}
                onComplete={() => {
                  setProcessingStatus({
                    ipfs: 'complete',
                    blockchain: 'complete',
                    tokens: 'complete'
                  });
                  setCurrentStep(4);
                }}
              />
            )}

            {currentStep === 4 && (
              <Results results={mockResults} />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CarbonTokenApp;