// API base URLs - these would come from environment variables in a real app
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.alignedinnovation.com/v1';
const S3_UPLOAD_URL = process.env.REACT_APP_S3_UPLOAD_URL || 'https://upload.alignedinnovation.com/v1';

// Utility function to handle API errors
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data);
    throw new Error(error.response.data.message || 'API request failed');
  }
  console.error('Network Error:', error);
  throw new Error('Network error occurred');
};

// File Upload Service
export const uploadFiles = async (files, onProgress) => {
  try {
    // First get pre-signed URLs for each file
    const presignedUrls = await fetch(`${S3_UPLOAD_URL}/presign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        files: files.map(f => ({ name: f.name, type: f.type }))
      })
    }).then(res => res.json());

    // Upload files in parallel with progress tracking
    const uploadPromises = files.map(async (file, index) => {
      const xhr = new XMLHttpRequest();
      
      // Track individual file progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(file.name, percentComplete);
        }
      };

      return new Promise((resolve, reject) => {
        xhr.open('PUT', presignedUrls[index].url);
        xhr.onload = () => resolve(presignedUrls[index].key);
        xhr.onerror = () => reject(new Error('Upload failed'));
        xhr.send(file);
      });
    });

    const uploadedKeys = await Promise.all(uploadPromises);
    return uploadedKeys;
  } catch (error) {
    handleApiError(error);
  }
};

// Header Mapping Service
export const submitHeaderMappings = async (mappings) => {
  try {
    const response = await fetch(`${API_BASE_URL}/mappings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mappings)
    });
    
    if (!response.ok) throw new Error('Failed to submit mappings');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Processing Status Service
export const getProcessingStatus = async (jobId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/status/${jobId}`);
    if (!response.ok) throw new Error('Failed to get status');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// IPFS Progress Service
export const getIpfsProgress = async (jobId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/ipfs/status/${jobId}`);
    if (!response.ok) throw new Error('Failed to get IPFS status');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// MassCertificate Storage Service
export const getMassCertificateStatus = async (jobId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/masscert/status/${jobId}`);
    if (!response.ok) throw new Error('Failed to get MassCertificate status');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Token Minting Service
export const mintTokens = async (type, amount, recipientAddress) => {
  try {
    // SECURITY TODO: recipientAddress cannot be trusted
    const response = await fetch(`${API_BASE_URL}/tokens/mint`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        tokenType: type, // 'carbon', 'vcm', or '45q'
        amount,
        recipientAddress
      })
    });
    
    if (!response.ok) throw new Error('Failed to mint tokens');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Form Generation Service
export const generate8933Form = async (tokenData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/forms/8933`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tokenData)
    });
    
    if (!response.ok) throw new Error('Failed to generate form');
    
    // Return blob for PDF download
    const blob = await response.blob();
    return blob;
  } catch (error) {
    handleApiError(error);
  }
};

// WebSocket Service for Real-time Updates
export class ProcessingSocket {
  constructor(jobId, onUpdate) {
    this.ws = new WebSocket(`${API_BASE_URL.replace('http', 'ws')}/ws/status/${jobId}`);
    
    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      onUpdate(data);
    };
    
    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  disconnect() {
    if (this.ws) {
      this.ws.close();
    }
  }
}

// Processing Job Creation Service
export const createProcessingJob = async (fileKeys, mappings) => {
  try {
    const response = await fetch(`${API_BASE_URL}/jobs`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fileKeys,
        mappings
      })
    });
    
    if (!response.ok) throw new Error('Failed to create processing job');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};